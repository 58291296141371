/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  box-sizing: content-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * using new name emphasis instead of primary
 */
/**
 * Used to style label in row (horizontal) layout
 */
/**
 * Used to style label in column (vertical) layout
 */
/**
 * using new name emphasis instead of primary
 */
/**
 * Used to style label in row (horizontal) layout
 */
/**
 * Used to style label in column (vertical) layout
 */
[i2gButton] {
  display: flex;
  align-items: center;
  justify-content: center;
}
[i2gButton].button-style-default {
  font-size: 16px;
  font-weight: 800;
  color: var(--storm-color);
  padding: 0 24px;
  box-sizing: border-box;
  min-width: 0;
  min-height: 48px;
  white-space: nowrap;
  background-color: var(--pure-white-color);
  border: solid 1px var(--storm-color);
  font-weight: 800;
}
[i2gButton].button-style-default:hover, [i2gButton].button-style-default:focus, [i2gButton].button-style-default:active {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: var(--storm-color);
}
[i2gButton].button-style-default:active, [i2gButton].button-style-default:focus {
  opacity: 0.9;
}
[i2gButton].button-style-default[disabled] {
  background-color: var(--ghost-color);
  color: var(--storm-color);
  opacity: 1;
  box-shadow: none;
}
[i2gButton].button-style-default-reversed {
  font-size: 16px;
  font-weight: 800;
  color: var(--pure-white-color);
  padding: 0 24px;
  box-sizing: border-box;
  min-width: 0;
  min-height: 48px;
  white-space: nowrap;
  background-color: var(--storm-color);
  border: solid 1px var(--pure-white-color);
  font-weight: 800;
}
[i2gButton].button-style-default-reversed:hover, [i2gButton].button-style-default-reversed:focus, [i2gButton].button-style-default-reversed:active {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: var(--pure-white-color);
}
[i2gButton].button-style-default-reversed:active, [i2gButton].button-style-default-reversed:focus {
  opacity: 0.9;
}
[i2gButton].button-style-default-reversed[disabled] {
  background-color: var(--ghost-color);
  color: var(--storm-color);
  opacity: 1;
  box-shadow: none;
}
[i2gButton].button-style-emphasis {
  font-size: 16px;
  font-weight: 800;
  color: var(--pure-white-color);
  padding: 0 24px;
  box-sizing: border-box;
  min-width: 140px;
  min-height: 48px;
  white-space: nowrap;
  background-color: var(--blue-color);
}
[i2gButton].button-style-emphasis:hover, [i2gButton].button-style-emphasis:focus, [i2gButton].button-style-emphasis:active {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: var(--pure-white-color);
}
[i2gButton].button-style-emphasis:active, [i2gButton].button-style-emphasis:focus {
  opacity: 0.9;
}
[i2gButton].button-style-emphasis[disabled] {
  background-color: var(--ghost-color);
  color: var(--storm-color);
  opacity: 1;
  box-shadow: none;
}
[i2gButton].button-style-alert {
  font-size: 16px;
  font-weight: 800;
  color: var(--pure-white-color);
  padding: 0 24px;
  box-sizing: border-box;
  min-width: 140px;
  min-height: 48px;
  white-space: nowrap;
  background-color: var(--red-color);
}
[i2gButton].button-style-alert:hover, [i2gButton].button-style-alert:focus, [i2gButton].button-style-alert:active {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: var(--pure-white-color);
}
[i2gButton].button-style-alert:active, [i2gButton].button-style-alert:focus {
  opacity: 0.9;
}
[i2gButton].button-style-alert[disabled] {
  background-color: var(--ghost-color);
  color: var(--storm-color);
  opacity: 1;
  box-shadow: none;
}
[i2gButton].button-style-tertiary {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: var(--blue-color);
  padding: 8px;
  white-space: nowrap;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 48px;
  min-width: 140px;
}
[i2gButton].button-style-tertiary:hover, [i2gButton].button-style-tertiary:focus, [i2gButton].button-style-tertiary:active {
  box-shadow: none;
  color: var(--blue-color);
  opacity: 0.9;
  background-color: transparent;
}
[i2gButton].button-style-tertiary[disabled] {
  opacity: 1;
  color: var(--ghost-color);
  background-color: transparent;
}
[i2gButton].button-style-tertiary .pull-left {
  width: 100%;
  text-align: left;
}
[i2gButton].button-style-tertiary .pull-right {
  width: 100%;
  text-align: right;
}
[i2gButton].button-style-tertiary-alert {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: var(--red-color);
  padding: 8px;
  white-space: nowrap;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 48px;
  min-width: 140px;
}
[i2gButton].button-style-tertiary-alert:hover, [i2gButton].button-style-tertiary-alert:focus, [i2gButton].button-style-tertiary-alert:active {
  box-shadow: none;
  color: var(--red-color);
  opacity: 0.9;
  background-color: transparent;
}
[i2gButton].button-style-tertiary-alert[disabled] {
  opacity: 1;
  color: var(--ghost-color);
  background-color: transparent;
}
[i2gButton] i2g-icon {
  margin-right: 8px;
}

a[i2gButton] {
  text-decoration: none;
  color: var(--black-color);
}

[i2gInput] {
  font-size: 16px;
  line-height: 20px;
  color: var(--black-color);
  padding: 8px 48px 8px 8px;
  border: 1px solid var(--storm-color);
  box-sizing: border-box;
  width: 100%;
  caret-color: var(--blue-color);
}
[i2gInput][disabled], [i2gInput][readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}
[i2gInput]:focus, [i2gInput].focus {
  border: 2px solid var(--blue-color);
  padding: 7px;
  padding: 7px 47px 7px 7px;
}
[i2gInput].ng-dirty.ng-invalid {
  border-color: var(--red-color);
  box-shadow: 0 0 0 1px var(--red-color) inset;
}
[i2gInput].value-alignment-center {
  text-align: center;
}
[i2gInput].value-alignment-right {
  text-align: right;
}

input[i2gInput] {
  height: 48px;
}

#app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}
#app-loading .spinner {
  width: 80px;
  height: 80px;
  color: var(--orange-color);
  animation-name: loadingSpinner;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#app-loading .i2g-logo {
  height: 150px;
  margin-left: 15px;
}

body.loaded #app-loading {
  display: none;
}
body.loaded #app-loading .spinner {
  animation-name: none;
}

[hidden] {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/** New Font **/
@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Regular.woff2) format("woff2"), url(../assets/fonts/Project-Regular.woff) format("woff");
}
@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Medium.woff2) format("woff2"), url(../assets/fonts/Project-Medium.woff) format("woff");
  font-weight: 700;
}
@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Bold.woff2) format("woff2"), url(../assets/fonts/Project-Bold.woff) format("woff");
  font-weight: 800;
}
@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Black.woff2) format("woff2"), url(../assets/fonts/Project-Black.woff) format("woff");
  font-weight: 900;
}
html,
button,
input,
select,
textarea {
  color: var(--black-color);
  font-family: ProjectFont, sans-serif !important;
}

body {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  --olive-color: #1b8559;
  --blue-color: #324bff;
  --red-color: #e80e51;
  --orange-color: #ff4b00;
  --gold-color: #ffc832;
  --black-color: #0c0c0d;
  --slate-color: #2b2c2e;
  --alpha-slate-color: rgba(22, 45, 61, 0.8);
  --storm-color: #565a5c;
  --ghost-color: #d7dadb;
  --fog-color: #f5f5f6;
  --pure-white-color: #ffffff;
}

strong {
  font-weight: bold;
}

input,
textarea {
  padding: 0;
  margin: 0;
  border: none;
}
input:focus,
textarea:focus {
  outline: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--storm-color) !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: var(--storm-color) !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--storm-color) !important;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: var(--storm-color) !important;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

textarea {
  resize: none;
}

button,
.router-link {
  border-radius: 8px;
}

button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
button:focus, button:active {
  outline: 0;
}

a {
  color: var(--blue-color);
}
a, a:hover {
  text-decoration: none;
}

a[disabled],
button[disabled],
label[disabled] {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.underline {
  text-decoration: underline;
}

.sr-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

.shimmer {
  background-color: var(--fog-color);
  animation-delay: 0s;
  animation-duration: 1.5s;
  animation-name: shimmer;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  animation-iteration-count: infinite;
  height: 100%;
}

.loading-spinner {
  color: var(--orange-color);
  animation-name: loadingSpinner;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.checkmark {
  color: var(--blue-color) !important;
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shimmer {
  0% {
    background-color: var(--fog-color);
  }
  50% {
    background-color: var(--fog-color);
    opacity: 0.9;
  }
  100% {
    background-color: var(--fog-color);
  }
}
@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes slideFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideFromRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes rotate180 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
.text-bold {
  font-weight: bold;
}